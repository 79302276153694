
/* EagleQMS.css */
/* Global styles */
:root {
  --primary-color: #0066ff;
  --primary-dark: #0050cc;
  --secondary-color: #5c2de3;
  --accent-color: #00d4ff;
  --text-color: #333;
  --text-light: #666;
  --text-lighter: #999;
  --bg-light: #f8f9fa;
  --bg-dark: #1a1a2e;
  --white: #ffffff;
  --black: #000000;
  --gray-100: #f8f9fa;
  --gray-200: #e9ecef;
  --gray-300: #dee2e6;
  --gray-400: #ced4da;
  --gray-500: #adb5bd;
  --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 10px 15px rgba(0, 0, 0, 0.1);
  --gradient-primary: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  --gradient-accent: linear-gradient(135deg, var(--accent-color), var(--primary-color));
  --border-radius-sm: 4px;
  --border-radius-md: 8px;
  --border-radius-lg: 12px;
  --transition-fast: 0.2s ease;
  --transition-normal: 0.3s ease;
  --transition-slow: 0.5s ease;
}

/* Reset and Base Styles */
.eagle-qms-page {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: var(--text-color);
  line-height: 1.6;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

/* Button Styles */
.primary-button, .cta-button.primary {
  background: var(--gradient-primary);
  color: var(--white);
  border: none;
  padding: 14px 28px;
  border-radius: var(--border-radius-md);
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  transition: transform var(--transition-fast), box-shadow var(--transition-fast);
  box-shadow: var(--shadow-md);
}

.primary-button:hover, .cta-button.primary:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-lg);
}

.secondary-button, .cta-button.secondary {
  background: var(--gradient-primary);
  color: var(--white);
  border: none;
  padding: 14px 28px;
  border-radius: var(--border-radius-md);
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  transition: transform var(--transition-fast), box-shadow var(--transition-fast);
  box-shadow: var(--shadow-md);
}

.secondary-button:hover, .cta-button.secondary:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-lg);
}

.outline-button {
  background: transparent;
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
  padding: 12px 24px;
  border-radius: var(--border-radius-md);
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  transition: all var(--transition-fast);
}

.outline-button:hover {
  background: rgba(0, 102, 255, 0.1);
}

.play-icon {
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  margin-left: 8px;
}

/* Section Headers */
.section-header {
  text-align: center;
  max-width: 800px;
  margin: 0 auto 60px;
  padding: 0 20px;
}

.section-badge {
  background: linear-gradient(135deg, rgba(0, 102, 255, 0.1), rgba(92, 45, 227, 0.1));
  color: var(--primary-color);
  padding: 8px 16px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 20px;
}

.section-badge.light {
  background: rgba(255, 255, 255, 0.2);
  color: var(--white);
}

.section-header h2 {
  font-size: 38px;
  margin: 0 0 16px;
  font-weight: 800;
}

.section-underline {
  width: 60px;
  height: 4px;
  background: var(--gradient-primary);
  margin: 0 auto 24px;
  border-radius: 2px;
}

.section-underline.light {
  background: var(--white);
}

.section-description {
  font-size: 18px;
  color: var(--text-light);
  margin: 0;
}

/* Hero Section */
.hero {
  position: relative;
  height: 100vh;
  min-height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: var(--white);
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--bg-dark);
  z-index: -2;
}

.hero-particles {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: radial-gradient(circle, rgba(255, 255, 255, 0.1) 1px, transparent 1px);
  background-size: 30px 30px;
  z-index: -1;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(26, 26, 46, 0.9), rgba(26, 26, 46, 0.7));
  z-index: -1;
}

.hero-content {
  max-width: 1200px;
  width: 90%;
  text-align: center;
  padding: 0 20px;
  z-index: 1;
}

.logo-container {
  margin-bottom: 30px;
}

.eagle-logo {
  font-size: 48px;
  font-weight: 900;
  letter-spacing: 2px;
  margin: 0;
  background: linear-gradient(135deg, var(--accent-color), var(--primary-color));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.logo-underline {
  width: 100px;
  height: 4px;
  background: var(--gradient-accent);
  margin: 10px auto 0;
  border-radius: 2px;
}

.hero-tagline {
  font-size: 42px;
  font-weight: 800;
  line-height: 1.2;
  margin: 0 0 24px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.hero-description {
  font-size: 20px;
  font-weight: 400;
  margin: 0 0 40px;
  opacity: 0.9;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.hero-cta {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 60px;
}

.hero-clients {
  opacity: 0.8;
}

.hero-clients p {
  font-size: 14px;
  margin-bottom: 20px;
}

.client-logos {
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
}

.client-logo {
  background: rgba(255, 255, 255, 0.1);
  padding: 10px 20px;
  border-radius: var(--border-radius-sm);
  backdrop-filter: blur(4px);
}

.hero-gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 200px;
  background: linear-gradient(to top, rgba(248, 249, 250, 1), rgba(248, 249, 250, 0));
  z-index: 0;
}

.hero-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: var(--bg-light);
  clip-path: ellipse(50% 50% at 50% 100%);
  z-index: 0;
}

/* Features Section */
.features {
  background: var(--bg-light);
  padding: 100px 0;
  position: relative;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.feature-card {
  background: var(--white);
  border-radius: var(--border-radius-lg);
  padding: 40px;
  box-shadow: var(--shadow-md);
  transition: transform var(--transition-normal), box-shadow var(--transition-normal);
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.feature-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 16px;
  background: linear-gradient(135deg, rgba(0, 102, 255, 0.1), rgba(92, 45, 227, 0.1));
  margin-bottom: 20px;
}

.feature-icon svg {
  font-size: 24px;
  color: var(--primary-color);
}

.feature-card h3 {
  font-size: 22px;
  margin: 0 0 16px;
}

.feature-card p {
  color: #121217;
  margin: 0 0 20px;
}

.feature-link {
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--primary-color);
  font-weight: 600;
  text-decoration: none;
  transition: gap var(--transition-fast);
}

.feature-link:hover {
  gap: 10px;
}

.feature-card-accent {
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 30%;
  background: var(--gradient-primary);
  border-radius: 2px;
}

/* Animation for scroll visibility */
.animate-on-scroll {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.animate-on-scroll.in-view {
  opacity: 1;
  transform: translateY(0);
}

/* Demo Section */
.demo-section {
  padding: 100px 0;
  background: var(--white);
}

.demo-container {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  padding: 0 20px;
}

.demo-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.demo-content h2 {
  font-size: 36px;
  margin: 16px 0;
}

.demo-content p {
  font-size: 18px;
  color: #1b1d1b;
  margin-bottom: 30px;
}

.demo-features {
  list-style: none;
  padding: 0;
  margin: 0 0 40px;
}

.demo-features li {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
  font-size: 16px;
}

.list-icon {
  color: var(--primary-color);
}

.demo-visual {
  display: flex;
  align-items: center;
  justify-content: center;
}

.demo-screen {
  width: 100%;
  max-width: 500px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  border: 1px solid var(--gray-300);
}

.screen-header {
  background: var(--gray-100);
  padding: 12px 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--gray-300);
}

.screen-dots {
  display: flex;
  gap: 6px;
  margin-right: 16px;
}

.screen-dots span {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--gray-400);
}

.screen-dots span:nth-child(1) {
  background: #ff5f57;
}

.screen-dots span:nth-child(2) {
  background: #febc2e;
}

.screen-dots span:nth-child(3) {
  background: #28c840;
}

.screen-title {
  font-size: 14px;
  color: var(--text-light);
}

.screen-content {
  background: var(--white);
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-placeholder {
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, var(--gray-100), var(--gray-200));
  display: flex;
  align-items: center;
  justify-content: center;
}

.placeholder-text {
  color: var(--text-lighter);
  font-size: 14px;
}

/* Stats Section */
.stats-section {
  background: var(--gradient-primary);
  color: var(--white);
  padding: 100px 0;
  position: relative;
  overflow: hidden;
}

.stats-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffffff' fill-opacity='0.05' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E");
  opacity: 0.5;
  z-index: 0;
}

.stats-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  z-index: 1;
}

.stats-header {
  text-align: center;
  margin-bottom: 60px;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 30px;
}

.stat-item {
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: var(--border-radius-lg);
  padding: 30px;
  transition: transform var(--transition-normal);
}

.stat-item:hover {
  transform: translateY(-5px);
}

.stat-icon {
  font-size: 24px;
  margin-bottom: 20px;
}

.stat-value {
  font-size: 42px;
  font-weight: 800;
  margin-bottom: 8px;
}

.stat-label {
  font-size: 16px;
  opacity: 0.8;
}

/* Testimonials Section */
.testimonials-section {
  padding: 100px 0;
  background: var(--white);
}

.testimonials-slider {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  padding: 0 20px;
}

.testimonial-card {
  background: var(--bg-light);
  border-radius: var(--border-radius-lg);
  padding: 40px;
  box-shadow: var(--shadow-md);
  transition: transform var(--transition-normal);
}

.testimonial-card:hover {
  transform: translateY(-5px);
}

.testimonial-quote {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 30px;
  position: relative;
  padding-left: 20px;
  border-left: 3px solid var(--primary-color);
}

.testimonial-author {
  display: flex;
  align-items: center;
  gap: 16px;
}

.author-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--gray-300);
}

.author-name {
  font-weight: 600;
  font-size: 16px;
}

.author-position {
  color: var(--text-light);
  font-size: 14px;
}

/* Recognition Section */
.recognition-section {
  padding: 50px 0 100px;
  background: var(--bg-light);
}

.recognition-cards {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  padding: 0 20px;
}

.recognition-card {
  border-radius: var(--border-radius-lg);
  padding: 40px;
  color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: var(--shadow-md);
  transition: transform var(--transition-normal);
}

.recognition-card:hover {
  transform: translateY(-5px);
}

.recognition-card.google {
  background: linear-gradient(135deg, #4285f4, #34a853);
}

.recognition-card.patent {
  background: linear-gradient(135deg, #f44336, #ff9800);
}

.recognition-logo {
  background: rgba(255, 255, 255, 0.2);
  padding: 15px;
  border-radius: var(--border-radius-md);
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.recognition-logo img {
  max-width: 80px;
  height: auto; 
}

.recognition-card h3 {
  font-size: 24px;
  margin: 0 0 16px;
}

.recognition-card p {
  opacity: 0.9;
  margin: 0 0 24px;
}

.recognition-link {
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--white);
  font-weight: 600;
  text-decoration: none;
  transition: gap var(--transition-fast);
}

.recognition-link:hover {
  gap: 10px;
}

/* CTA Section */
.cta-section {
  padding: 100px 0;
  background: var(--gradient-primary);
  color: var(--white);
  position: relative;
  overflow: hidden;
}

.cta-background-shapes .shape {
  position: absolute;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
}

.shape-1 {
  width: 300px;
  height: 300px;
  top: -150px;
  left: -100px;
}

.shape-2 {
  width: 200px;
  height: 200px;
  bottom: -100px;
  right: 10%;
}

.shape-3 {
  width: 150px;
  height: 150px;
  top: 50px;
  right: -50px;
}

.cta-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  padding: 0 20px;
  position: relative;
  z-index: 1;
}

.cta-content h2 {
  font-size: 36px;
  margin: 16px 0;
}

.cta-content p {
  font-size: 18px;
  opacity: 0.9;
  margin-bottom: 40px;
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
}

.cta-guarantee {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  opacity: 0.8;
}

.guarantee-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
}

/* Scroll to top button */
.scroll-top-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--white);
  color: var(--primary-color);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  box-shadow: var(--shadow-md);
  z-index: 100;
  transition: transform var(--transition-fast), box-shadow var(--transition-fast);
}

.scroll-top-button:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

/* Mobile-first responsive adjustments */
@media (max-width: 480px) {
  .eagle-qms-page {
    font-size: 14px;
  }
  
  .hero {
    min-height: 100vh;
    height: auto;
    padding: 80px 0 40px;
  }
  
  .hero-content {
    width: 95%;
    padding: 0 10px;
  }
  
  .eagle-logo {
    font-size: 32px;
    margin-bottom: 15px;
  }
  
  .hero-tagline {
    font-size: 24px;
    margin-bottom: 16px;
  }
  
  .hero-description {
    font-size: 16px;
    margin-bottom: 30px;
  }
  
  .hero-cta {
    flex-direction: column;
    gap: 12px;
    margin-bottom: 40px;
  }
  
  .primary-button, .cta-button.primary, 
  .secondary-button, .cta-button.secondary {
    padding: 12px 20px;
    font-size: 14px;
    height: 44px;
    width: 100%;
  }
  
  .client-logos {
    gap: 15px;
    flex-wrap: wrap;
  }
  
  .client-logo {
    padding: 8px 12px;
    font-size: 12px;
  }
  
  .section-header h2 {
    font-size: 26px;
  }
  
  .section-description {
    font-size: 16px;
  }
  
  .features {
    padding: 60px 0;
  }
  
  .feature-card {
    padding: 25px;
  }
  
  .feature-card h3 {
    font-size: 20px;
  }
  
  .demo-section {
    padding: 60px 0;
  }
  
  .demo-content h2 {
    font-size: 26px;
  }
  
  .demo-content p {
    font-size: 16px;
  }
  
  .stats-section, .cta-section {
    padding: 60px 0;
  }
  
  .stat-value {
    font-size: 32px;
  }
  
  .cta-content h2 {
    font-size: 28px;
  }
}
/* Video Modal Styles */
.video-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.video-modal-container {
  position: relative;
  width: 90%;
  max-width: 1000px;
  aspect-ratio: 16 / 9;
  background: transparent;
  border-radius: var(--border-radius-lg);
  overflow: hidden;
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.3);
}

.modal-close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: rgba(255, 255, 255, 0.2);
  color: var(--white);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  transition: background var(--transition-fast);
}

.modal-close-button:hover {
  background: rgba(255, 255, 255, 0.3);
}

.video-wrapper {
  width: 100%;
  height: 100%;
}

.video-wrapper iframe {
  width: 100%;
  height: 100%;
  border: none;
}

/* Mobile Responsive Adjustments */
@media (max-width: 768px) {
  .video-modal-container {
    width: 95%;
    max-width: 95%;
  }

  .modal-close-button {
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
  }
}

/* Extra small devices (320px and below) */
@media (max-width: 320px) {
  .hero-tagline {
    font-size: 22px;
  }
  
  .hero-description {
    font-size: 15px;
  }
  
  .section-header h2 {
    font-size: 24px;
  }
  
  .feature-card {
    padding: 20px;
  }
  
  .demo-screen {
    border-radius: 12px;
  }
  
  .stats-grid {
    grid-template-columns: 1fr;
  }
  
  .stat-item {
    padding: 20px;
  }
}
@media (max-width: 1024px) {
  .footer-content {
    grid-template-columns: 1fr 1fr;
    gap: 40px;
  }

  .hero-tagline {
    font-size: 18px;
  }

  .demo-container {
    grid-template-columns: 1fr;
  }
  .demo-visual {
    order: -1;
  }
}

