.contact-wrapper {
  display: flex;
  height: 100vh; /* Full height of the viewport */
  gap: 40px; /* Adds space between the form section and map section */
}

.contact-form-section {
  width: 40%; /* Reduced width for left column */
  padding: 0 2rem 2rem 2rem;
  overflow-y: auto; /* Allow scrolling if content is long */
  margin-top: 4rem;
}

.map-section {
  width: 55%; /* Adjust the map width accordingly */
  height: auto;
  padding: 2rem;
  margin-top: 5rem;
  margin-bottom: 4rem;
}

.contact-header {
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 1;
  padding-top: 2rem;
  background-color: white;
  padding-bottom: 1rem;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact-form label {
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.contact-form input,
.contact-form select,
.contact-form textarea {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  width: 100%;
}

.contact-form textarea {
  height: 120px;
}

.contact-form button {
  padding: 15px;
  background-color: #00a46b;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #000000;
}

.map-section h2 {
  text-align: center;
  margin-bottom: 2rem;
}

.map-section iframe {
  width: 100%;
  height: 100%; /* Full height of the map section */
  border: none;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .contact-wrapper {
    flex-direction: column;
  }

  .contact-form-section,
  .map-section {
    width: 100%;
  }

  .map-section iframe {
    height: 250px;
  }

  .map-section {
    margin-top: 0;
  }
}

@media (max-width: 480px) {
  .map-section {
    padding: 0 1rem;
  }

  .map-section h2 {
    text-align: center;
    margin-bottom: 1rem;
  }
  .contact-wrapper {
    gap: 5px;
  }
  .contact-form-section {
    padding-bottom: 0;
  }
  .contact-header {
    padding-bottom: 0;
    position: relative;
  }
}
