.home-container {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 0;
}

#bg-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.content-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  z-index: 1;
  animation: fadeIn 2s ease-in-out;
}

.title {
  font-size: 4rem;
  font-weight: bold;
  letter-spacing: 5px;
  margin-bottom: 20px;
  font-family: "Arial Black", sans-serif;
}

.subtitle {
  font-size: 1.5rem;
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.5;
  font-family: "Arial", sans-serif;
  color: #ddd;
}

@media (max-width: 768px) {
  .title {
    font-size: 2.5rem;
    letter-spacing: 2px;
  }

  .subtitle {
    font-size: 1.2rem;
    max-width: 90%;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 4rem;
  }

  .subtitle {
    font-size: 1.2rem;
  }
}

/* Light grey background section */
.about-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 100px;
  background-color: #f0f0f0;
  height: 100vh;
}
.about-text {
  width: 60%;
}

.about-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: "Arial", sans-serif;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  border-right: 4px solid transparent;
  width: fit-content;
}

.title-text-type {
  width: fit-content;
}

.typewriter-active {
  border-right: 4px solid;
  animation: typing 3.5s steps(40, end), blink 0.75s step-end infinite;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.about-paragraph {
  font-size: 1.25rem;
  line-height: 1.8;
  color: #333;
  max-width: 90%;
  font-family: "Arial", sans-serif;
}

.about-icon {
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ai-image {
  width: 25rem;
  height: auto;
}

@media (max-width: 768px) {
  .about-section {
    flex-direction: column;
    padding: 30px;
    height: auto;
  }

  .about-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .about-para-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
  }

  .about-icon {
    width: 100%;
    margin-top: 30px;
  }

  .ai-image {
    width: 60%;
  }
}

@media (max-width: 480px) {
  .about-title {
    font-size: 1.2rem;
    width: fit-content;
  }

  .about-paragraph {
    font-size: 1rem;
  }

  .about-para-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
  }

  .ai-image {
    width: 80%;
  }
}

@media (max-width: 440px) {
  .about-title {
    font-size: 1.1rem;
    width: fit-content;
  }

  .about-paragraph {
    font-size: 0.9rem;
  }

  .about-para-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
  }

  .ai-image {
    width: 80%;
  }

  .about-section {
    padding: 25px;
  }
}

.parent-solutions {
  padding: 60px 100px 80px 100px;
}

/* Section Heading */
.section-heading {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 5rem;
  font-family: "Arial", sans-serif;
  color: #333;
}

/* Card Container */
.card-container {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  padding: 0 100px;
}

/* From Uiverse.io by eslam-hany */
.book {
  position: relative;
  border-radius: 10px;
  width: 18rem;
  height: 20rem;
  /* background-color: whitesmoke; */
  background-color: black;
  -webkit-box-shadow: 1px 1px 5px #000;
  box-shadow: 1px 1px 5px #000;
  -webkit-transform: preserve-3d;
  -ms-transform: preserve-3d;
  transform: preserve-3d;
  -webkit-perspective: 2000px;
  perspective: 2000px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
}

.cover {
  top: 0;
  position: absolute;
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform-origin: 0;
  -ms-transform-origin: 0;
  transform-origin: 0;
  box-shadow: 1px 1px 5px #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.book:hover .cover {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform: rotatey(-80deg);
  -ms-transform: rotatey(-80deg);
  transform: rotatey(-80deg);
}

.inner-text {
  padding-left: 3.4rem;
  padding-right: 1rem;
}
.book p {
  font-size: 20px;
  font-weight: bolder;
}

.cover img {
  width: 18rem;
  height: auto;
}

/* Emerging Technologies Section */
.emerging-tech-section {
  padding: 80px 100px;
  background-color: #f0f0f0;
}

.emerging-tech-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.emerging-tech-icon {
  width: 27rem;
  height: auto;
}

.emerging-tech-text {
  width: 50%;
}

.emerging-tech-text h2 {
  font-size: 2.5rem;
}

.tech-subheading {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #666;
}

.tech-paragraph {
  margin-top: 2rem;
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #333;
}

.tech-button {
  width: 100%;
  padding: 15px 0;
  border: none;
  background-color: black;
  color: white;
  font-size: 1.2rem;
  border-radius: 50px;
  cursor: pointer;
  margin-top: 2rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .card-container {
    flex-direction: column;
    align-items: center;
    padding: 0 40px;
  }

  .book {
    width: 18rem;
    height: 20rem;
    margin-bottom: 20px;
  }

  .emerging-tech-container {
    flex-direction: column;
  }

  .emerging-tech-text {
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  .emerging-tech-icon {
    width: 80%;
  }
}

@media (max-width: 480px) {
  .section-heading {
    font-size: 2rem;
  }

  .tech-subheading {
    font-size: 1.2rem;
  }

  .tech-paragraph {
    font-size: 1rem;
  }

  .tech-button {
    font-size: 1rem;
  }
}

/* Our Team Section */
.our-team-section {
  text-align: center;
  padding: 60px 20px;
  background-color: #f9f9f9;
}

.our-team-heading {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
  font-family: "Arial", sans-serif;
}

.our-team-text {
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
  color: #555;
  font-family: "Arial", sans-serif;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .our-team-heading {
    font-size: 2rem;
  }

  .our-team-text {
    font-size: 1rem;
    padding: 0 20px;
  }
}

@media (max-width: 480px) {
  .our-team-heading {
    font-size: 1.8rem;
  }

  .our-team-text {
    font-size: 0.9rem;
  }
}

/* Footer Section */
.footer {
  background-color: #000; /* Black background */
  color: #fff; /* White text */
  padding: 20px 0;
  text-align: center;
  width: 100%;
}

.footer-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Arial", sans-serif;
}

.company-name {
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 2px;
  color: #fff;
  margin-bottom: 15px;
}

/* Footer Section */
.footer {
  background-color: #111; /* Darker black for a richer feel */
  color: #fff; /* White text */
  padding: 40px 0;
  text-align: center;
  width: 100%;
  position: relative;
}

.footer-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Arial", sans-serif;
}

/* Company Name */
.company-name {
  font-size: 2rem;
  font-weight: 100;
  letter-spacing: 3px;
  color: #fff;
  margin-bottom: 25px;
  text-transform: uppercase;
}

/* Footer Links */
.footer-links {
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
  display: flex;
  gap: 40px;
}

.footer-links li {
  display: inline;
}

.footer-links li a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease, transform 0.3s ease;
  position: relative;
}

.footer-links li a:hover {
  color: #ffab00; /* Nice accent color on hover */
  transform: translateY(-3px); /* Slight upward hover animation */
}

.footer-links li a::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #ffab00;
  transition: width 0.3s ease, left 0.3s ease;
}

.footer-links li a:hover::after {
  width: 100%;
  left: 0;
}

/* Divider Line */
.footer-divider {
  width: 80%;
  height: 1px;
  background-color: #444; /* Subtle divider line */
  margin: 20px 0;
}

/* Copyright Text */
.footer-copyright {
  font-size: 1rem;
  color: #888; /* Lighter grey for less focus */
  margin-top: 15px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .company-name {
    font-size: 1.6rem;
  }

  .footer-links {
    flex-direction: column;
    gap: 20px;
  }

  .footer-links li a {
    font-size: 1.1rem;
  }

  .footer-copyright {
    font-size: 0.9rem;
  }

  .emerging-tech-section {
    padding: 60px 40px;
    background-color: #f0f0f0;
  }
}

@media (max-width: 480px) {
  .company-name {
    font-size: 1.4rem;
  }

  .footer-links {
    gap: 15px;
  }

  .footer-links li a {
    font-size: 1rem;
  }

  .footer-copyright {
    font-size: 0.8rem;
  }
}
