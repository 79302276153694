:root {
  --deep-sea: #0d1b2a;
  --ocean-teal: #1b4965;
  --wave-foam: #cae9ff;
  --parchment: #f4e4bc;
  --crow-feather: #2b2d42;
  --gold-trim: #e0b13d;
  --rope-brown: #8d5524;
  --text-light: #f5f6f5;
  --text-dark: #2b2d42;
  --text-accent: #e0b13d;

  /* Background colors */
  --light-oceanic: #E6F0FA; 
  --medium-oceanic: #B3D4E5;
  --darker-oceanic: #8AB8D6;
}

.crowsnestContainer {
  background: var(--light-oceanic);
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  font-family: 'Georgia', serif;
  color: var(--text-light);
}

/* Hero Section - Unchanged */
/* Hero Section */
.crowsnestHero {
  height: 100vh; /* Full height for desktop */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: linear-gradient(135deg, var(--deep-sea) 0%, var(--ocean-teal) 100%);
}

.oceanWaves {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40%; /* Default height for desktop */
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="%231b4965" fill-opacity="0.7" d="M0,192L48,181.3C96,171,192,149,288,154.7C384,160,480,192,576,197.3C672,203,768,181,864,160C960,139,1056,117,1152,122.7C1248,128,1344,160,1392,176L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>') repeat-x;
  animation: waves 15s infinite ease-in-out;
}

.crowFlightPath {
  position: absolute;
  top: 20%;
  width: 100%;
  height: 100px; /* Default height for desktop */
}

.crowAnimated {
  width: 80px; /* Default size for desktop */
  height: 80px;
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M20 50 Q30 30 40 50 Qmelt50 40 60 50 Q70 30 80 50 L90 60 Q80 70 70 60 Q60 80 50 60 Q40 70 30 60 Z" fill="%232b2d42"/></svg>');
  animation: flyAcross 25s infinite linear;
}

.heroContent {
  text-align: center;
  color: var(--text-light);
  z-index: 2;
  padding: 2rem;
}

.crowsnestLogo {
  font-size: 5rem; /* Default size for desktop */
  font-weight: bold;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);
  animation: float 4s infinite ease-in-out;
}

.logoCrow {
  color: var(--wave-foam);
}

.logoNest {
  color: var(--gold-trim);
}

.heroContent h1 {
  font-size: 2.5rem; /* Default size for desktop */
  margin: 1rem 0;
}

.heroTagline {
  color: var(--wave-foam);
  font-size: 1.5rem; /* Default size for desktop */
}

.compassDecor {
  position: absolute;
  top: -20px;
  right: -20px;
  width: 60px; /* Default size for desktop */
  height: 60px;
  background: radial-gradient(circle, var(--gold-trim) 20%, transparent 70%);
  border-radius: 50%;
  opacity: 0.7;
}

.decorativeRope {
  width: 200px; /* Default width for desktop */
  height: 4px;
  background: var(--rope-brown);
  margin: 1rem auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

/* Info Section */
.infoSection {
  padding: 3rem 1rem;
  position: relative;
  background: var(--light-oceanic);
  display: flex;
  justify-content: center; 
}

.parchmentBg {
  background: var(--parchment);
  padding: 2rem;
  border-radius: 20px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1;
  color: var(--text-dark);
  width: 700px; 
  height: 300px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sectionContent {
  text-align: center;
  margin: 0;
}

.parchmentBg h2 {
  color: var(--crow-feather);
  margin: 0 0 1rem 0;
  font-size: 2rem;
}

.parchmentBg p {
  margin: 0.5rem 0;
  font-size: 1rem; 
}

.sextantDecor {
  position: absolute;
  bottom: -30px;
  right: 5%;
  width: 100px;
  height: 100px;
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><circle cx="50" cy="50" r="40" fill="none" stroke="%23e0b13d" stroke-width="4"/><path d="M50 10 A40 40 0 0 1 90 50" fill="none" stroke="%23e0b13d" stroke-width="2"/></svg>');
  opacity: 0.8;
}
/* Features Section */
.featuresSection {
  padding: 5rem 2rem;
  background: var(--light-oceanic); 
  color: var(--text-dark);
  position: relative;
}

.featuresSection h2 {
  color: var(--text-accent);
}

.waveTop, .waveBottom {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100px;
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="%230d1b2a" d="M0,96L48,112C96,128,192,160,288,160C384,160,480,128,576,112C672,96,768,96,864,112C960,128,1056,160,1152,160C1248,160,1344,128,1392,112L1440,96L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg>');
  z-index: 1;
}

.waveTop { top: -1px; }
.waveBottom { bottom: -1px; transform: rotate(180deg); }

.featuresGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  gap: 2rem;
  padding: 2rem 0;
}

.featureCard {
  background: var(--deep-sea);
  padding: 2rem;
  border-radius: 15px;
  border: 2px solid var(--gold-trim);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
}

.featureCard:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.featureCard h3 {
  color: #e0b13d;
}

.featureCard p {
  color: var(--text-light);
}

.featureIcon {
  width: 70px;
  height: 70px;
  margin: 0 auto 1rem;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5));
  color: var(--text-light);
}

/* Users Section  */
.usersSection {
  padding: 5rem 2rem;
  background: var(--light-oceanic); 
  color: var(--text-dark);
}

.usersSection h2 {
  color: var(--text-accent);
  text-align: center;
  margin-bottom: 2rem;
}

.ropeDivider {
  height: 10px;
  background: var(--rope-brown);
  width: 50%;
  margin: 0 auto 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
}

.usersGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 1.5rem;
}

.userCard {
  text-align: center;
  padding: 1.5rem;
  background: var(--deep-sea); 
  border-radius: 10px;
}

.userCard h3 {
  color: var(--wave-foam);
}

.userCard p {
  color: var(--text-light);
}

.userIcon {
  width: 50px;
  height: 50px;
  margin: 0 auto 1rem;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5));
  color: var(--text-light);
}

/* Technology Section */
.technologySection {
  padding: 3rem 1rem; 
  position: relative;
  background: var(--light-oceanic);
  display: flex;
  justify-content: center;
}

.technologySection .parchmentBg {
  background: var(--parchment);
  padding: 2rem;
  border-radius: 15px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1;
  color: var(--text-dark);
  width: 500px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.technologySection h2 {
  color: var(--crow-feather);
  text-align: center;
  margin: 0 0 1.5rem 0;
  font-size: 2rem; 
}

.techContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  text-align: center;
  margin: 0;
}

.techItem {
  max-width: 250px;
  margin: 0;
}

.techItem h3 {
  color: var(--text-accent);
  margin: 0.5rem 0;
  font-size: 1.5rem; 
}

.techItem p {
  margin: 0;
  font-size: 1rem; 
}

.techIconAi,
.techIconGlobal,
.techIconSecurity {
  margin: 0 auto 0.5rem auto;
  display: block;
  width: 40px;
  height: 40px;
}
/* CTA Section  */
.ctaSection {
  padding: 6rem 2rem;
  background: var(--darker-oceanic);
  color: var(--text-dark);
  position: relative;
  overflow: hidden;
}

.ctaSection h2 {
  color: var(--text-light); 
}

.ctaContent {
  text-align: center;
  z-index: 1;
}

.ctaContent p {
  color: var(--wave-foam);
  margin: 0.5rem 0 1.5rem 0; 
}

.lighthouseBeam {
  position: absolute;
  top: 0;
  left: 50%;
  width: 200px;
  height: 200%;
  background: conic-gradient(from 90deg at 50% 0%, transparent 0deg, rgba(255, 255, 255, 0.3) 20deg, transparent 40deg);
  transform: translateX(-50%);
  animation: beam 8s infinite linear;
}

.ctaButton {
  padding: 1.2rem 2.5rem;
  background: var(--gold-trim);
  border: none;
  border-radius: 30px;
  color: var(--deep-sea);
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.ctaButton:hover {
  transform: scale(1.1);
  box-shadow: 0 0 15px var(--gold-trim);
}

.anchorDecor {
  position: absolute;
  bottom: 20px;
  left: 5%;
  width: 80px;
  height: 80px;
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M50 10 V40 M40 30 H60 M50 40 V70 Q40 80 30 70 H70 Q60 80 50 70 M30 70 H70" fill="none" stroke="%23e0b13d" stroke-width="4"/></svg>');
  opacity: 0.7;
}

/* Animations */
@keyframes waves {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

@keyframes flyAcross {
  0% { transform: translateX(-100vw); }
  100% { transform: translateX(100vw); }
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

@keyframes beam {
  0% { transform: translateX(-50%) rotate(0deg); }
  100% { transform: translateX(-50%) rotate(360deg); }
}

/* Hero Section Adjustments */
.crowsnestHero {
  height: auto; 
  min-height: 750px; 
  padding: 4rem 1rem; 
}

.crowsnestLogo {
  font-size: 3.5rem; 
  margin-bottom: 1rem;
}

.heroContent h1 {
  font-size: 2rem; 
  line-height: 1.2;
}

/* Features Section Adjustments */
.featuresSection h2 {
  color: #e0b13d;
  font-size: 2.2rem;
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
  position: relative;
  z-index: 2;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.3);
}

.featureCard h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

/* Parchment Background Adjustments */
.parchmentBg {
  width: 90%; /* More responsive width */
  max-width: 800px; /* Maximum width */
  height: auto; /* Flexible height */
  min-height: 300px; /* Minimum height */
  padding: 2.5rem;
}

.sectionContent {
  max-width: 600px;
}

/* Ensure text contrast */
.infoSection h2,
.technologySection h2 {
  color: var(--crow-feather) !important;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .crowsnestLogo {
    font-size: 2.8rem;
  }
  
  .heroContent h1 {
    font-size: 1.8rem;
  }
  
  .featuresSection h2 {
    font-size: 1.8rem;
  }

  /* Technology Section */
.technologySection {
  padding: 2rem 0.5rem;
}

.technologySection .parchmentBg {
  width: 90%;
  height: auto;
  min-height: 350px;
  padding: 1.5rem;
}

.technologySection h2 {
  font-size: 1.5rem;
}

.techItem h3 {
  font-size: 1.2rem;
}

.techItem p {
  font-size: 0.9rem;
}

.techIconAi,
.techIconGlobal,
.techIconSecurity {
  width: 30px;
  height: 30px;
}

}