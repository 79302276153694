.jobs-container {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.jobs-video-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 0;
}

#bg-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.jobs-content-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  z-index: 1;
  animation: fadeIn 2s ease-in-out;
}

.jobs-title {
  font-size: 4rem;
  font-weight: bold;
  letter-spacing: 5px;
  margin-bottom: 20px;
  font-family: "Arial Black", sans-serif;
  width: 70rem;
}

@media (max-width: 990px) {
  .jobs-title {
    font-size: 3rem;
    letter-spacing: 2px;
    width: fit-content;
  }
}

@media (max-width: 768px) {
  .jobs-title {
    font-size: 3rem;
    letter-spacing: 2px;
    width: fit-content;
  }
}

@media (max-width: 480px) {
  .jobs-title {
    font-size: 3rem;
    width: fit-content;
  }
}

/* Our Team Section */
.info-section {
  text-align: center;
  padding: 60px 20px;
  background-color: #f9f9f9;
  text-align: justify;
}

.info-text {
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
  color: #555;
  font-family: "Arial", sans-serif;
}

@media (max-width: 768px) {
  .info-text {
    font-size: 1rem;
    padding: 0 20px;
  }
}

@media (max-width: 480px) {
  .info-text {
    font-size: 0.9rem;
  }
}

.key-features-section {
  width: 100%;
  padding: 5rem 8rem;
}

.key-features-heading {
  text-align: center;
  font-size: 3rem;
  margin-bottom: 40px;
}

.key-feature-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5rem;
  gap: 20px;
}

.key-feature-row .key-feature-image {
  width: 15rem;
  height: auto;
  object-fit: cover;
}

.key-feature-row .key-feature-text {
  width: 55%;
}

.key-feature-text h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.key-feature-text p {
  font-size: 1rem;
  line-height: 1.5;
  text-align: justify;
}

.key-feature-row .reverse {
  flex-direction: row-reverse;
}

@media screen and (max-width: 768px) {
  .key-feature-row,
  .key-feature-row.reverse {
    flex-direction: column;
  }

  .key-feature-row.reverse {
    flex-direction: column;
  }

  .key-feature-row .key-feature-image,
  .key-feature-row .key-feature-text {
    width: 100%;
  }

  .key-features-section {
    width: 100%;
    padding: 60px 40px 0px 40px !important;
  }
}

.chose-us-section {
  padding: 60px 20px;
  background-color: #f9f9f9;
}

.chose-us-section {
  width: 100%;
  padding: 40px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.chose-us-heading {
  font-size: 2rem;
  margin-bottom: 30px;
  color: #333;
}

.chose-us-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 30px;
}

.chose-us-image {
  width: 8rem;
  max-width: 250px;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

.chose-us-text {
  margin-top: 10px;
  font-size: 1rem;
  color: #555;
}

@media screen and (max-width: 768px) {
  .chose-us-item {
    flex: 1 1 calc(50% - 20px);
  }

  .chose-us-row {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 480px) {
  .chose-us-row {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-bottom: 30px;
  }
}

/* Add animation for scroll appearance in key features */
.key-feature-row {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.key-feature-row.visible {
  opacity: 1;
  transform: translateY(0);
}
