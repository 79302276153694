/* 
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background-color: #000;
  color: #fff;
  font-family: "Arial", sans-serif;
  width: 100%;
  top: 0;
  z-index: 10;
}

.navbar-logo {
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 2px;
}

.navbar ul {
  padding: 0;
  margin: 0;
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 3.5rem;
  justify-content: space-between;
}

.navbar-links li a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s ease;
}

.navbar-links li a:hover {
  color: #bbb;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
  }

  .navbar-links {
    flex-direction: column;
    gap: 5px;
    margin-top: 1.5rem !important;
  }

  .navbar-links li a {
    font-size: 1.2rem;
  }
} */

/* 
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background-color: #000;
  color: #fff;
  font-family: "Arial", sans-serif;
  width: 100%;
  top: 0;
  z-index: 10;
}

.navbar-logo {
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 2px;
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 3.5rem;
}

.navbar-links li a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s ease;
}

.navbar-links li a:hover {
  color: #bbb;
}

.navbar-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 4px 0;
  transition: 0.3s;
}

@media (max-width: 768px) {
  .navbar-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 40px;
    background-color: #000;
    padding: 20px;
    gap: 10px;
    width: 200px;
    border-radius: 8px;
  }

  .navbar-links.open {
    display: flex;
  }

  .navbar-toggle {
    display: flex;
  }
} */

/* Reset some basic styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Basic styling for the navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background-color: #000;
  color: #fff;
  font-family: "Arial", sans-serif;
  width: 100%;
  top: 0;
  z-index: 10;
  position: fixed;
}

/* Logo styling */
.navbar-logo {
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 2px;
  cursor: pointer;
}

/* Links styling */
.navbar-links {
  list-style: none;
  display: flex;
  gap: 3.5rem;
}

.navbar ul {
  padding: 0;
  margin: 0;
}

.navbar-links li a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  position: relative;
  transition: color 0.3s ease;
}

.navbar-links li a:hover {
  color: #ffab00; /* Change text color to yellow on hover */
}

/* Add yellow border animation on hover */
.navbar-links li a::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #ffab00; /* Yellow underline */
  transition: width 0.3s ease, left 0.3s ease;
}

.navbar-links li a:hover::after {
  width: 100%;
  left: 0;
}

.navbar-logo:hover {
  color: #ffab00;
}

.navbar-logo::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 1px;
  background-color: #ffab00; /* Yellow underline */
  transition: width 0.3s ease, left 0.3s ease;
}

.navbar-logo:hover::after {
  width: 100%;
  left: 0;
}

/* Hamburger button styling */
.navbar-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 4px 0;
  transition: 0.3s;
}

/* Show/hide menu for mobile */
@media (max-width: 768px) {
  .navbar-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 40px;
    background-color: #000;
    padding: 20px;
    gap: 10px;
    width: 200px;
    border-radius: 8px;
  }

  /* When open, show the menu */
  .navbar-links.open {
    display: flex;
    padding: 2%;
  }
  .navbar-links.open li {
    display: flex;
    padding: 2%;
  }

  /* Show the hamburger button */
  .navbar-toggle {
    display: flex;
  }
}
