.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.scroll-button {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 5% 0.7rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1.8rem;
  border-radius: 2rem;
}

.scroll-button:hover {
  opacity: 1;
  background-color: #ffab00;
}

.scroll-button:focus {
  outline: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.scroll-button {
  animation: fadeIn 0.3s;
}
