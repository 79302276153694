/* Footer container */
footer {
  background: linear-gradient(135deg, #032451, black);

  color: #ffffff; /* White text */
  padding: 40px 20px;
  text-align: center; /* Center-align all text */
  border-top: 1px solid #ffffff;
}

/* Title styles */
.footer-title {
  font-family: "Varino", serif;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
}

/* Horizontal line styles */
footer hr {
  border: none; /* Remove the default border */
  border-top: 1px solid #ffffff; /* Thin white line */
  width: 50%; /* Half the width of the container */
  margin: 2rem auto; /* Center it and add vertical spacing */
}

/* Links container: Flexbox for center alignment */
.footer-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center align horizontally */
  gap: 15px; /* Spacing between links */
  margin: 10px 0;
}

/* Link styles */
footer a {
  color: #f8f9fa; /* Light text for links */
  text-decoration: none; /* Remove underline */
  font-size: 1rem; /* Adjust font size */
  margin: 1rem;
}

footer a:hover {
  color: #4a7cc3; /* Highlight color for hover effect */
}
.Address {
  max-width: 800px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center; /* This will center items horizontally */
  margin: 0 auto; /* This will center the `.Address` container itself */
}

/* Copyright text */
.footer-copyright {
  margin-top: 2rem;
  font-size: 13px; /* Smaller font for copyright */
  text-align: center; /* Ensure it's centered */
}
